#superadmin-layout-wrapper {
  height: auto;
  margin: 0;
  padding: 59px 0px 4px 76px;
  box-sizing: border-box;
  height: 100vh;
  overflow: auto;
  transition: all 0.5s ease;
  /* transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms; */
  background-color: #F7F8FA;
}
#superadmin-layout-wrapper .content-wrapper {
  /* background: #fff; */
  /* border-radius: 10px; */
  margin-left: 8px;
  min-height: 500px;
  box-shadow: 4px 4px 4px rgba(136, 136, 136, 0.2);
}

.compressIcon { 
  color: black;
  /* cursor: pointer;
  font-size: 1.2rem;
  position: absolute;
  z-index: 1000;
  right: 13rem;
  top: 1.6rem; */
}

@media screen and (min-width: 768px) {
  #superadmin-layout-wrapper {
    height: auto;
    margin: 0;
    padding: 59px 0px 4px 155px;
    box-sizing: border-box;
    height: 100vh;
    overflow: auto;
    transition: all 0.5s ease;
    /* transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms; */
    background-color: #F7F8FA;
  }

  .footer {
    padding-top: 5px;
    font-size: 0.7em;
    justify-content: flex-end;
    z-index: 1;
    background-color: #ECF0F1;
    color: #536471;
    width: calc(100% - 190px);
    padding-right: 40px;
    padding-bottom: 4px;
    /* left: auto; */
    right: 0;
    bottom: 0;
    position: fixed;
  }
}
