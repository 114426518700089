#container {
  height: 514px;
  width: 930px;
  margin: 50px auto;
}
.timeline {
  margin: 20px;
  margin-bottom: 60px;
}
.timeline .timeline-months {
  display: flex;
  padding-left: 30px;
}
.timeline .timeline-months-month {
  width: 10px;
  margin: 2px;
  border: 1px solid transparent;
  font-size: 10px;
}
.timeline .timeline-months .Jan ~ .Jan,
.timeline .timeline-months .Feb ~ .Feb,
.timeline .timeline-months .Mar ~ .Mar,
.timeline .timeline-months .Apr ~ .Apr,
.timeline .timeline-months .May ~ .May,
.timeline .timeline-months .Jun ~ .Jun,
.timeline .timeline-months .Jul ~ .Jul,
.timeline .timeline-months .Aug ~ .Aug,
.timeline .timeline-months .Sep ~ .Sep,
.timeline .timeline-months .Oct ~ .Oct,
.timeline .timeline-months .Nov ~ .Nov,
.timeline .timeline-months .Dec ~ .Dec {
  visibility: hidden;
}
.timeline-body {
  display: flex;
}
.timeline-body .timeline-weekdays {
  display: inline-flex;
  flex-direction: column;
  width: 30px;
}
.timeline-body .timeline-weekdays-weekday {
  font-size: 10px;
  height: 10px;
  border: 1px solid transparent;
  margin: 2px;
  vertical-align: middle;
}
.timeline-body .timeline-cells {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 112px;
}
.timeline-body .timeline-cells-cell {
  height: 40px;
  width: 60px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 2px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.05);
}
.timeline-body .timeline-cells-cell:hover {
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.arrowProd {
  transform: rotate(270deg);
}
.pushResult {
  border: solid 0.5px rgb(175, 174, 174);
}
.chartContainer {
  width: 400px;
  height: 300px;
  background-color: rgb(219, 216, 216);
  border-radius: 20px;
}
#reactgooglegraph-1
  > div
  > div:nth-child(1)
  > div
  > svg
  > g:nth-child(4)
  > g
  > rect:nth-child(3) {
  display: none;
}
#reactgooglegraph-1 > div > div:nth-child(1) > div > svg {
  border-radius: 20px;
}
#reactgooglegraph-1
  > div
  > div:nth-child(1)
  > div
  > svg
  > g:nth-child(3)
  > rect {
  text-align: center;
}
#reactgooglegraph-2 > div > div:nth-child(1) > div > svg {
  border-radius: 20px;
}
#reactgooglegraph-3 > div > div:nth-child(1) > div > svg {
  border-radius: 20px;
}
#reactgooglegraph-4 > div > div:nth-child(1) > div > svg {
  border-radius: 20px;
}
#reactgooglegraph-5 > div > div:nth-child(1) > div > svg {
  border-radius: 20px;
}
#reactgooglegraph-6 > div > div:nth-child(1) > div > svg {
  border-radius: 20px;
}
#reactgooglegraph-2
  > div
  > div:nth-child(1)
  > div
  > svg
  > g:nth-child(4)
  > g
  > rect:nth-child(3) {
  display: none;
}
#reactgooglegraph-3
  > div
  > div:nth-child(1)
  > div
  > svg
  > g:nth-child(4)
  > g
  > rect:nth-child(3) {
  display: none;
}
#reactgooglegraph-4
  > div
  > div:nth-child(1)
  > div
  > svg
  > g:nth-child(4)
  > g
  > rect:nth-child(3) {
  display: none;
}
#reactgooglegraph-5
  > div
  > div:nth-child(1)
  > div
  > svg
  > g:nth-child(4)
  > g
  > rect:nth-child(3) {
  display: none;
}
#reactgooglegraph-6
  > div
  > div:nth-child(1)
  > div
  > svg
  > g:nth-child(4)
  > g
  > rect:nth-child(3) {
  display: none;
}
.overlays {
  border-radius: 15px;
}
.mapboxgl-map {
  border-radius: 15px;
}
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}
.mapboxgl-popup-tip {
  border-radius: 15px !important;
}
.mapboxgl-popup-content {
  border-radius: 15px !important;
  padding: 10px !important;
}
.mapboxgl-popup-close-button {
  margin: 3px 3px 0 0 !important;
}
body
  > div.makeStyles-modal-87
  > div.makeStyles-paper-88
  > div:nth-child(2)
  > div
  > div.MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded
  > div:nth-child(1)
  > div
  > div.ReactVirtualized__Table__headerRow.MuiVirtualizedTable-tableRow-91.MuiVirtualizedTable-flexContainer-89
  > div:nth-child(2)
  > div {
  text-align: center !important;
}
/* #reactgooglegraph-1
  > div
  > div:nth-child(1)
  > div
  > svg
  > g:nth-child(3)
  > rect {
  position: absolute;
} */
.itemDivHeader {
  width: 400px !important;
  position: absolute !important;
  margin-right: 0px !important;
  background-color: rgb(237, 237, 237) !important;
  z-index: 1 !important;
  margin-top: 0px !important;
  height: 50px !important;
  border-radius: 15px 15px 0px 0px !important;
}
div.google-visualization-tooltip {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px !important;
  height: auto !important;
  width: 100px !important;
}

.bg-PIE_CHART_GREEN {
  background-color: #28A745 !important;
}

.bg-PIE_CHART_ORANGE {
  background-color: #FFC007 !important;
}

.bg-PIE_CHART_RED {
  background-color: #DC3444 !important;
}