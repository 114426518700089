.c-pointer {
  cursor: pointer;
}

.MuiTablePagination-spacer {
  display: none;
}

.MuiTablePagination-caption {
  font-size: 12px !important;
}

.MuiFormHelperText-contained {
  margin: 5px 2px 0 !important;
}

.MuiTableCell-body {
  padding: 5px !important;
}

.MuiTableCell-root {
  padding: 15px 5px !important;
}

.myDatePickerWrapper .react-datepicker-wrapper,
.myDatePickerWrapper react-datepicker__input-container {
  width: 100%;
}

.myDatePickerWrapper .react-datepicker-wrapper,
.myDatePickerWrapper react-datepicker__input-container {
  width: 100%;
}

.myDatePickerWrapper input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.inputDatePicker {
  position: relative;
}

.inputDatePicker > .inputDatePicker__label {
  position: absolute;
  z-index: 1000;
  top: -7px;
  left: 10px;
  font-size: 11px;
  background: white;
  padding: 0 3px;
  color:rgba(0, 0, 0, 0.54) !important;
  font-weight: 500;
}


.inputDatePicker input {
  width: 100%;
  height: 56px;
  padding: 18.5px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.inputDatePicker input:focus {
  outline: 1px solid #005297 !important;
}



.inputDatePicker .react-datepicker-wrapper,
.inputDatePicker react-datepicker__input-container {
  width: 100%;
}

.inputDatePickerDevice input {
  width: 100%;
  height: 42px;
  padding: 18.5px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.inputDatePickerDevice .react-datepicker-wrapper,
.inputDatePickerDevice react-datepicker__input-container {
  width: 100%;
}

.content-wrapper {
  box-shadow: none !important;
}

.MuiTableCell-body {
  padding: 5px !important;
}

.table-wrapper-statistics table {
  min-height: 200px !important;
}

.table-wrapper-statistics .makeStyles-paper-253 {
  margin-bottom: 0px !important;
}

.table-wrapper-statistics {
  display: flex;
  align-items: flex-end;
}

.table-wrapper-statistics td {
  height: 35px !important;
  padding: 0px !important;
}

.table-wrapper-statistics tr {
  max-height: 35px !important;
  height: 35px !important;
}

.logs-table th {
  /* // width: 25% !important; */
}

.logs-table td {
  white-space: nowrap;
}

.new-entry {
  animation: 0.5s ease-out drop 1;
}

.apexcharts-canvas {
  margin: auto !important;
}

.noContent-div {
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  font-weight: 600;
}

.adjust-label .MuiFormControlLabel-label {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infinite-scroll-component::-webkit-scrollbar {
  display: none;
}

.infinite-scroll-component {
  -ms-overflow-style: none;
}

.MuiTablePagination-toolbar {
  min-width: 470px;
}

.logs-toolbar {
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 5px;
  margin-left: 10px;
}

.login-links {
  color: #39a8e0;
  text-decoration: none;
}

.resend-otp-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.microwave-chart-container > div {
  width: 100%;
  min-height: 540px;
}

.my-microwave-graph-div > div {
  width: 100%;
  min-height: 350px;
}

.switch {
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  height: 30px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 62px;
}
.switch__label {
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 100%;
}
.switch__input {
  opacity: 0;
}
.switch_input:checked ~ .switch_text {
  background: #0090bb;
}
.switch_input:checked ~ .switch_text:before {
  opacity: 0;
  transform: translateX(200%);
}
.switch_input:checked ~ .switch_text:after {
  opacity: 1;
  transform: translateX(0);
}
.switch_input:checked ~ .switch_handle {
  transform: translateX(60px);
}
.switch__text {
  background-color: #e5e5e5;
  border-radius: 50px;
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  height: 24px;
  margin: 0;
  position: absolute;
  top: 0;
  width: 56px;
}
.switch_text:before,
.switch_text:after {
  color: SlateGrey;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  position: absolute;
  transition: all 0.2s ease-in-out;
  transition-property: transform;
}
.switch__text:before {
  content: attr(data-off);
  right: 8px;
  transform: translateX(0);
}
.switch__text:after {
  color: white;
  content: attr(data-on);
  left: 9px;
  opacity: 0;
  transform: translateX(-200%);
}
.switch__handle {
  background-color: white;
  border-radius: 50%;
  display: block;
  height: 26px;
  margin: 2px;
  padding: 0;
  position: absolute;
  margin-top: 17px;
  top: 0;
  transition: all 0.2s ease-in-out;
  transition-property: transform;
  width: 26px;
}
.switch--large {
  height: 30px;
  width: 90px;
}
.switch--large .switch_input:checked ~ .switch_handle {
  transform: translateX(180px);
}
.switch--large .switch__text {
  height: 30px;
  width: 70px;
}
.switch--large .switch_text:before,
.switch--large .switch_text:after {
  font-size: 14px;
  line-height: 30px;
  position: absolute;
  margin-top: 15px;
}
.switch--large .switch__text:before {
  right: 10px;
}
.switch--large .switch__text:after {
  left: 12px;
}
.switch--large .switch__handle {
  height: 30px;
  width: 26px;
}
.switch--no-text .switch_text:before,
.switch--no-text .switch_text:after {
  display: none;
}
.switch--success .switch_input:checked ~ .switch_text {
  background: #5ed37a;
}

.graph-container-microwave {
  min-width: 1200px;
}

.switch-toggle-container {
  position: fixed;
  left: 45%;
  transform: translate(-50%, -50%);
}

@keyframes drop {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE and Edge */
.hide-scrollbar {
  -ms-overflow-style: none;
}

.MuiChip-root {
  font-size: 8px;
}

.word-break-break-all {
  word-break: break-all;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.react-datepicker-popper {
  z-index: 99999999 !important;
}

/* .health_and_monitor_right_section::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
/* .health_and_monitor_right_section { */
  /* -ms-overflow-style: none;  IE and Edge */
  /* scrollbar-width: none;  Firefox */
/* } */ 

.kitting-plan-restock-input-fields {
  background: none;
  border: none;
  min-width: 100px;
  border-bottom: 1.5px solid #a5a5a5;
  padding: 5px 0px;
  text-align: center;
  font-size: 0.8rem;
  outline: none;
}

.kitting-plan-restock-input-fields:focus {
  border-bottom: 1.5px solid #202020;
}
.MuiTab-root {
text-transform: capitalize !important;
}
.MuiTabs-root {
  background-color: #F7F8FA !important;
}
.MuiTabs-indicator {
  height: 2px !important;
}