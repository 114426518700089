.content-wrapper {
    box-shadow: none !important;
  }
  
  .MuiTablePagination-spacer {
    display: none;
  }
  
  .MuiTablePagination-caption {
    font-size: 12px !important;
  }
  
  .MuiFormHelperText-contained {
    margin: 5px 2px 0 !important;
  }
  .MuiTableCell-body {
    padding: 5px !important;
  }
  
  .MuiTableCell-body {
    padding: 5px !important;
  }
  
  .table-wrapper-statistics table {
    min-height: 200px !important;
  }
  
  .table-wrapper-statistics .makeStyles-paper-253 {
    margin-bottom: 0px !important;
  }
  
  .table-wrapper-statistics {
    display: flex;
    align-items: flex-end;
  }
  
  .table-wrapper-statistics td {
    height: 35px !important;
    padding: 0px !important;
  }
  
  .table-wrapper-statistics tr {
    max-height: 35px !important;
    height: 35px !important;
  }
  
  g[aria-labelledby$="title"] {
    display: none;
  }
  
  .MuiTablePagination-toolbar {
    min-width: 470px;
  }
  
  .inputDatePickerDevice input {
    width: 100%;
    height: 42px;
    padding: 18.5px;
    border-radius: 4px;
    border: 1px solid #ced4da;
  }
  
  .inputDatePickerDevice .react-datepicker-wrapper,
  .inputDatePickerDevice react-datepicker__input-container {
    width: 100%;
  }
  
  