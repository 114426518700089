tr.MuiTableRow-footer::after {
    content: '';
}

.sticky-table {
    overflow: auto;
    max-height: 400px
}

.sticky-footer {
    position: sticky;
    background: white;
    bottom: 0
}